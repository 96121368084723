/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // start to gf stuff

        jQuery('.ui-datepicker-calendar td a').attr('data-testing', 'test');


        //add accordian for event features, and event exclude
        $("li.gf-event-features").before('<div id="features" class="event-features-select">Event Features</div>');
        $('.event-features-select').click( function() {
            $(".gf-event-features").toggleClass("visable");
        } );
        $("li.gf-event-exclude").not('.gfield_visibility_hidden').before('<div id="features" class="event-exclude-select">Event Exclude</div>');
        $('.event-exclude-select').click( function() {
            $(".gf-event-exclude").toggleClass("visable");
        } );

        //make date picker read only
        if (jQuery("li.gf_readonly")){ jQuery("li.gf_readonly input").attr("readonly","readonly"); }

        //set dedault date

        // var event_start = '<?php get_field("event_start_date", "options") ?>';
        // var event_end = '<?php the_field("event_end_date") ?>';
        // $( ".datepicker" ).datepicker({ dateFormat: 'yy-mm-dd', defaultDate: '2017-11-02', minDate: '2017-11-02', maxDate: '2017-11-05' });

        //end of gf

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      $(document).ready(function(){
        $('.bxslider').bxSlider({
          minSlides: 2,
          maxSlides: 6,
          slideWidth: 170,
          slideMargin: 30,
          moveSlides: 1,
          pager: false,
          touchEnabled: false,
        });
      });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
